export interface MetaConfiguration {
  title: string;
  description: string;
  identity: string;
  languages: string[];
  spiderable: string; // https://developers.google.com/search/docs/advanced/robots/robots_meta_tag
  image: string;
  favicon: string;
  twitterSite: string;
  twitterCreator: string;
  app: string;
  recipientPortalUrls: string[];
  spotlightUrl: string;
  duplicateOf: string;
  samlSsoUrl: string;
}
