import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleCookiesService } from '@accredible-frontend-v2/services/cookies';
import { AccredibleUrlHelper } from '@accredible-frontend-v2/utils/url-helper';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AccountsRedirectionKey, APPS } from './accounts-redirection.model';

@Injectable({
  providedIn: 'root',
})
export class AccredibleAccountsRedirectionService {
  private readonly _accountsSettingsUrl = `${environment.accountsUrl}/settings/`;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _browserStorage: AccredibleBrowserStorageService,
    private readonly _cookies: AccredibleCookiesService,
  ) {}

  // ------------------------------
  // GET NON-AUTHENTICATED URLS
  getLoginUrlOrigin(app?: APPS): string {
    return this._buildAccountsUrlForNonAuthenticatedUser(
      `${environment.accountsUrl}/login`,
      this._document.location.origin,
      app,
    );
  }

  getLoginUrlHref(app?: APPS): string {
    return this._buildAccountsUrlForNonAuthenticatedUser(
      `${environment.accountsUrl}/login`,
      _getUrlHrefWithoutHash(this._document.location.href),
      app,
    );
  }

  getLoginUrlCustomOrigin(origin: string, app: APPS = 'recipient-portal'): string {
    return this._buildAccountsUrlForNonAuthenticatedUser(
      `${environment.accountsUrl}/login`,
      origin,
      app,
    );
  }

  getRetrieveCredentialsUrl(app?: APPS): string {
    return this._buildAccountsUrlForNonAuthenticatedUser(
      `${environment.accountsUrl}/retrieve-credentials`,
      this._document.location.origin,
      app,
    );
  }

  getForgotPasswordUrl(app?: APPS): string {
    return this._buildAccountsUrlForNonAuthenticatedUser(
      `${environment.accountsUrl}/forgot-password`,
      _getUrlHrefWithoutHash(this._document.location.href),
      app,
    );
  }

  // ------------------------------
  // GET AUTHENTICATED URLS
  getPersonalInformationUrl(app: APPS): string {
    return this._buildAccountsUrlForAuthenticatedUser(
      `${this._accountsSettingsUrl}personal-information`,
      app,
    ).href;
  }

  getSetPasswordUrl(app: APPS): string {
    const url = this._buildAccountsUrlForAuthenticatedUser(
      `${this._accountsSettingsUrl}security`,
      app,
    );
    url.searchParams.set(AccountsRedirectionKey.SET_PASSWORD, 'true');
    return url.href;
  }

  getPrivacyVisibilityUrl(app: APPS): string {
    return this._buildAccountsUrlForAuthenticatedUser(
      `${this._accountsSettingsUrl}privacy-visibility`,
      app,
    ).href;
  }

  getDirectoryProfilesUrl(app: APPS): string {
    return this._buildAccountsUrlForAuthenticatedUser(
      `${this._accountsSettingsUrl}directory-profiles`,
      app,
    ).href;
  }

  getSecurityUrl(app: APPS): string {
    return this._buildAccountsUrlForAuthenticatedUser(`${this._accountsSettingsUrl}security`, app)
      .href;
  }

  getCourseFinderUrl(): string {
    const token = this._cookies.get(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
    const url = new URL(environment.courseFinderUrl);
    if (token) {
      url.searchParams.set('token', token);
    }
    return url.href;
  }

  // ------------------------------

  private _buildAccountsUrlForNonAuthenticatedUser(
    urlStr: string,
    origin: string,
    app: APPS = 'recipient-portal',
  ): string {
    const url = AccredibleUrlHelper.createUrl(urlStr, 'Accounts url with origin');
    url?.searchParams.set(AccountsRedirectionKey.APP, app);
    url?.searchParams.set(AccountsRedirectionKey.ORIGIN, origin);
    this._addLanguageQueryParam(url);
    return url?.href;
  }

  private _buildAccountsUrlForAuthenticatedUser(urlStr: string, app: APPS): URL {
    const url = AccredibleUrlHelper.createUrl(urlStr, 'Accounts url with redirect to');
    url?.searchParams.set(AccountsRedirectionKey.APP, app);
    url?.searchParams.set(AccountsRedirectionKey.HOST, this._document.location.host);
    this._addLanguageQueryParam(url);
    this._addSessionTokenQueryParam(url);
    return url;
  }

  private _addLanguageQueryParam(url: URL): void {
    const language = this._browserStorage.get(AccountsRedirectionKey.LANGUAGE);
    if (language) {
      url?.searchParams.set(AccountsRedirectionKey.LANGUAGE, language);
    }
  }

  private _addSessionTokenQueryParam(url: URL): void {
    // Add session token to url in order to persist login to accounts when dealing with one time token logins.
    // TODO: We are always adding the session token to the url but,
    //  ideally we would want to only send the token if the user is actually logged in with a one time token.
    url?.searchParams.set(
      AccountsRedirectionKey.SESSION_TOKEN_QUERY_PARAM,
      this._cookies.get(AccountsRedirectionKey.SESSION_TOKEN_COOKIE),
    );
  }
}

/**
 * We always need to remove the (GetSocial) hash of URL's when redirecting to accounts in order to avoid login issues
 */
const _getUrlHrefWithoutHash = (href: string): string => {
  const url = new URL(href);
  url.hash = '';
  return url.href;
};
