import { AccredibleCredential, AccredibleCustomCredential } from './credential.model';
import { AccredibleDesign } from './group.model';

export interface WalletCredential {
  id: number;
  uuid: string;
  name: string;
  url?: string;
  external?: boolean;
  username?: string;

  certificateDesign?: AccredibleDesign;
  badgeDesign?: AccredibleDesign;
  imageUrl?: string;

  issuerId: number;
  issuerName: string;
  issuedOn: string;

  // Used on transcript page
  website?: string;
  websiteLabel?: string;
  grade?: string;

  isPrivate: boolean;
  privateKey?: string;
  privateUrl?: string;
  isExpired: boolean;
  isCustomCredential: boolean;

  credential?: AccredibleCredential;
  customCredential?: AccredibleCustomCredential;

  // Used for selection
  disabled?: boolean;
  selected?: boolean;

  [key: string]: unknown;
}
